import { createI18n } from "vue-i18n"

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    const locales = require.context("./lang", true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key).default
        }
    })
    return messages
}

function getLocale() {
    switch (window.location.pathname.split("/")[1]) {
        case "en":
        case "tmpen":
        case "en4":
            return "en"
        case "de":
        case "tmpde":
        case "de4":
            return "de"
        case "nl":
        case "tmpnl":
        case "nl4":
            return "nl"
        case "dk":
        case "tmpdk":
        case "dk4":
            return "en"
        case "es":
        case "tmpes":
        case "es4":
            return "es"
        case "fi":
        case "tmpfi":
        case "fi4":
            return "en"
        case "no":
        case "tmpno":
        case "no4":
            return "en"
        case "se":
        case "tmpse":
        case "se4":
            return "en"
        default:
            return (
                window.location.pathname.replaceAll("/", "").split("-")[
                    window.location.pathname.replaceAll("/", "").split("-")
                        .length - 1
                ] || process.env.VUE_APP_I18N_LOCALE
            )
    }
}

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: getLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
    messages: loadLocaleMessages(),
})
